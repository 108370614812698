<template>
  <v-content>
      <v-container grid-list-lg fluid fill-height >
        <v-layout wrap align-top justify-left nested>
          <v-flex xs12 lg12 xl12>
            <app-title title="Historial de notificaciones">
              <template slot="subtitle">
                Hay <span class="font-weight-bold"> {{ totalNotSee }} </span> notificaiones sin leer
              </template>
            </app-title>
          </v-flex>
          <v-flex xs12 lg12 md12>
            <v-list two-line class="elevation-1 pa-0">
              <template v-for="(item, index) in items">
                <v-list-tile
                  :class="`${(item.readStatus === false) ? 'no-read' : ''} ${setColor(item.link.type)}`"
                  :key="item.key"
                  avatar
                  ripple>
                  <v-list-tile-action>
                    <v-checkbox
                      color="primary"
                      class="ml-1"
                      @dblclick.native="handleDobleClick"
                      v-model="notificationsSelected"
                      :value="item.key"
                      v-if="!item.readStatus"
                    ></v-checkbox>
                    <v-icon v-else> check_circle </v-icon>
                    </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ item.body }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{ item.user.name }}
                      {{ (item.readStatus) ? ` - Leido por ${item.readUser}` : '' }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-list-tile-action-text :class="setColorText(item.link.type)">
                      {{ $moment.unix(item.time).format('dddd DD-MM-YYYY, hh:mm:ss') }}
                    </v-list-tile-action-text>
                    <v-btn
                      color="accent"
                      v-if="item.link.type == 2 || item.link.type == 3"
                      class="ml-1"
                      :to="{ name: 'orderIndex', params: { id: item.link.info }}"
                      icon flat>
                      <v-icon> reply </v-icon>
                    </v-btn>
                  </v-list-tile-action>

                </v-list-tile>
                <v-divider
                  v-if="index + 1 < items.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
            <infinite-loading @infinite="getItems">
                <div slot="no-more" class="pt-2 pb-0"> No hay mas notificaciones</div>
                <div slot="no-results" class="pt-2 pb-0">No results message</div>
            </infinite-loading>
          </v-flex>
        </v-layout>

      </v-container>
    <v-btn
      fixed
      dark
      fab
      bottom
      right
      color="secondary"
      @click="handleClick"
      v-if="notificationsSelected.length > 0"
    >
      <v-icon>drafts</v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import appTitle from '../useful/title.vue';
import { firestore } from '../../fireStore';

export default {
  name: 'NotificationsHome',
  components: { appTitle, InfiniteLoading },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    setColorText(type) {
      // eslint-disable-next-line radix
      type = parseInt(type);
      switch (type) {
        case 1:
          return 'white--text';
        case 2:
          return 'white--text';
        case 3:
          return 'dark--text';
        default:
          return '';
      }
    },
    setColor(type) {
      // eslint-disable-next-line radix
      type = parseInt(type);
      switch (type) {
        case 1:
          return 'info white--text';
        case 2:
          return 'success white--text';
        case 3:
          return 'warning dark--text';
        default:
          return '';
      }
    },
    handleClick() {
      const self = this;
      this.notificationsSelected.forEach((element) => {
        firestore.collection('notifications').doc(String(self.user.storeID)).collection('store').doc(element)
          .update({
            readStatus: true,
            readUser: self.user.name,
          });
      });
      this.notificationsSelected = [];
      this.getItems();
      this.getTotal();
    },
    handleDobleClick() {
      this.dobleSelected = !this.dobleSelected;
      const notificationsSelected = [];
      if (this.dobleSelected) {
        const notRead = this._.filter(this.items, ['readStatus', false]);
        notRead.forEach((element) => {
          notificationsSelected.push(element.key);
        });
      }
      this.notificationsSelected = notificationsSelected;
    },
    getItems($state) {
      const self = this;
      firestore.collection('notifications').doc(String(self.user.storeID)).collection('store').orderBy('time', 'desc')
        .limit(this.paginate)
        .get()
        .then((querySnapshot) => {
          const notifications = [];
          querySnapshot.forEach((doc) => {
            const notify = doc.data();
            notify.key = doc.id;
            notifications.push(notify);
          });
          if (querySnapshot.size === self.items.length) {
            if ($state) {
              $state.complete();
            }
          } else {
            self.items = notifications;
            self.paginate += 20;
            if ($state) {
              $state.loaded();
            }
          }
        });
    },
    getTotal() {
      const self = this;
      firestore.collection('notifications').doc(String(self.user.storeID)).collection('store').where('readStatus', '==', false)
        .get()
        .then((querySnapshot) => {
          self.totalNotSee = querySnapshot.size;
        });
    },
  },
  data() {
    return {
      items: [],
      notificationsSelected: [],
      totalNotSee: 0,
      paginate: 20,
      dobleSelected: false,
    };
  },
  mounted() {
    this.getTotal();
  },
};
</script>
